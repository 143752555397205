<template>
  <div>

      <div class="card">
          <div class="bg-blue-light px-1 rounded pb-2">
              <TitleButton
                  class="mt-1"
                  btnTitle="Go Back"
                  title="Edit Receipt"
                  @onClickCloseButton="goToList"
              />

              <div class="row mt-1">
                  <div class="cols-12 col-sm-6 col-lg-3">
                      <label for="colFormLabel" class="col-form-label">Date</label>
                      <input
                          class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                          tabindex="1"
                          type="date"
                          placeholder=""
                          v-model="receipt.date"
                      >
                  </div>
                  <div class="cols-12 col-sm-6 col-lg-3">
                      <label for="colFormLabel" class="col-form-label">Voucher</label>
                      <input type="hidden" class="form-control invoice-edit-input date-picker flatpickr-input" readonly="readonly">
                      <div
                        class="input-group input-group-merge invoice-edit-input-group has-validation"
                        :class="{'is-invalid': isEmptyVoucher}"
                      >
                          <div class="input-group-text">
                              <span :class="{'text-danger': isEmptyVoucher}">{{ receipt.voucher_prefix }}-</span>
                          </div>
                          <input
                            type="text"
                            class="form-control invoice-edit-input"
                            placeholder=""
                            aria-describedby="validationVoucherNameFeedback"
                            v-model="receipt.voucher_serial"
                            :class="{'is-invalid': isEmptyVoucher}"
                          >
                      </div>
                  </div>
                  <div class="cols-12 col-sm-6 col-lg-3">
                      <label for="colFormLabel" class="col-form-label">Received in</label>
                      <v-select
                          placeholder="Select Cash & Bank Account"
                          class="w-100"
                          v-model="debit.account_head_id"
                          :options="cashAndBack"
                          label="name"
                          :reduce="name => name.id"
                      />
                  </div>
                  <div class="cols-12 col-sm-6 col-lg-3">
                      <label for="colFormLabel" class="col-form-label">Receipt Reference date </label>
                      <input
                              class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                              tabindex="1"
                              type="date"
                              placeholder=""
                              v-model="receipt.payment_ref_date"
                      >
                  </div>
                  <div class="cols-12 col-sm-6 col-lg-3">
                      <label for="colFormLabel" class="col-form-label">Receipt Reference</label>
                      <input
                              type="text"
                              class="form-control invoice-edit-input"
                              placeholder="Receipt Reference"
                              v-model="receipt.payment_ref">
                  </div>
                  <div class="cols-12 col-sm-6 col-lg-3">
                      <label for="colFormLabel" class="col-form-label">Project</label>
                      <v-select
                              placeholder="Select Project"
                              v-model="projectId"
                              :options="projects"
                              label="name"
                              :reduce="name => name.id"
                              @option:selected="onSelectProject"
                      />
                  </div>
                  <div class="cols-12 col-sm-6 col-lg-3">
                      <label for="colFormLabel" class="col-form-label">Donors</label>
                      <v-select
                              placeholder="Select Donor"
                              class="w-100"
                              :options="donors"
                              label="name"
                              :reduce="name => name.id"
                              v-model="donorId"
                      />
                  </div>
                  <div class="cols-12 col-sm-6 col-lg-3">
                      <label for="colFormLabel" class="col-form-label">Cost Centre</label>
                      <v-select
                              placeholder="Select Cost Centre"
                              v-model="costCentresId"
                              :options="costCentres"
                              label="name"
                              :reduce="name => name.id"
                      />
                  </div>
                  <div class="cols-12 col-sm-6 col-lg-3">
                      <label for="colFormLabel" class="col-form-label">Party</label>
                      <v-select
                              placeholder="Select Party"
                              v-model="debit.contact_profile_id"
                              :options="contactProfiles"
                              label="name"
                              :reduce="name => name.id"
                      />
                  </div>
                  <div class="cols-12 col-sm-6 col-lg-3">
                      <label for="colFormLabel" class="col-form-label">Business</label>
                      <v-select
                              placeholder="Select Business"
                              class="w-100"
                              :options="businesses"
                              label="name"
                              :reduce="name => name.id"
                              v-model="debit.business_id"
                      />
                  </div>
              </div>
          </div>

          <div class="card p-2">
              <template v-for="(item, index) in receipt.ledgers" :key="index">
                  <AddFormElement
                          class="mb-2"
                          :accountHeads="accountHeads"
                          :ngoabHeads="ngoabHeads"
                          :budgets="budgets"
                          :programs="programs"
                          :projects="projects"
                          :costCentres="costCentres"
                          :contactProfiles="contactProfiles"
                          :businesses="businesses"
                          :paymentDate="receipt.date"
                          v-if="item.debit == null"
                          :key="index"
                          :index="index"
                          :item="item"
                          @onClose="onClose"
                  />
              </template>

              <div class="row me-1 mt-3">
                  <div class="col-12 col-sm-4 col-lg-8 mb-1 mb-sm-0">
                      <AddButton title="Add line" @onClickAdd="onClickAdd"/>
                  </div>
                  <div class="col-6 col-sm-4 col-lg-4"><input readonly type="number" class="form-control text-end" :value="totalCredit"></div>
              </div>
              <div class="row mt-3">
                  <div class="col-12">
                      <div class="mb-2">
                          <label for="attachment" class="form-label fw-bold">Attachment</label>
                          <BlobFileViewer :attachments="receipt.attachments" />
                          <FileUpload
                                  :is-drag-active="true"
                                  button-text="Upload Documents"
                                  v-model="receipt.attachments"
                          >
                          </FileUpload>
                      </div>
                  </div>
              </div>
              <div class="row mt-3">
                  <div class="col-12">
                      <div class="mb-2">
                          <label for="note" class="form-label fw-bold">Memo</label>
                          <textarea v-model="receipt.note" placeholder="Memo" class="form-control" rows="2" id="note"></textarea>
                      </div>
                  </div>
              </div>

              <div class="mt-2 text-center">
                  <hr class="mb-1">
                  <button
                      :disabled="receiptLoading || isEmptyVoucher"
                      @click="updateReceipt()"
                      class="btn btn-primary me-1"
                  >
                      <div v-if="receiptLoading" class="spinner-border spinner-border-sm text-light" role="status">
                          <span class="visually-hidden">Loading...</span>
                      </div>
                      Update
                  </button>
                  <button
                          @click="goToList"
                          class="btn btn-outline-secondary cancel-btn"
                  >
                      Cancel
                  </button>
              </div>
          </div>
      </div>
      <VoucherValidationModal
          ref="voucherValidationModal"
          :voucherQuery="voucherQuery"
          @on-press-ok="setVoucherNumberAndDate(true)"
      />
  </div>
</template>

<script setup>
import handleReceipt from '@/services/modules/receipt'
import {computed, inject, onMounted, ref, watch} from 'vue'
import handleContact from '@/services/modules/contact'
import {editVoucherNumber, generateQuery, generateVoucherNumber} from "@/services/utils/voucherNumberGenerator";
import handleProjects from "@/services/modules/procurement/project";
import handleCostCentres     from "@/services/modules/ngo/costCentre";
import {useRoute, useRouter} from "vue-router";

import TitleButton from '@/components/atom/TitleButton'
import AddFormElement from '@/components/molecule/ngo/receipt/AddFormElement'
import AddButton from '@/components/atom/AddButton'
import BlobFileViewer from "@/components/atom/BlobFileViewer";
import FileUpload from "@/components/atom/FileUpload";
import handleFD6Projects from "@/services/modules/procurement/fd6";
import handleNGOPrograms from "@/services/modules/ngo/program";
import handleCBusinesses from '@/services/modules/businesses'
import VoucherValidationModal from "@/components/molecule/company/voucher/VoucherValidationModal.vue";
import figureFormatter from "@/services/utils/figureFormatter";
import handleJournal from '@/services/modules/journal'

const {fetchCashAndBank, editReceipt, receiptLoading, fetchSingleReceipt} = handleReceipt()
const {fetchContactProfiles} = handleContact()
const {fetchProjects} = handleProjects()
const {fetchCostCentreList} = handleCostCentres()
const {fetchFD6AccountsAll} = handleFD6Projects()
const {fetchProgramList} = handleNGOPrograms()
const {fetchBusinessList} = handleCBusinesses ()
const {decimalFormat} = figureFormatter();
const {fetchAccountHeads} = handleJournal()

const $router = useRouter();
const $route = useRoute();
const showError =  inject('showError');
const showSuccess =  inject('showSuccess');
const cashAndBack = ref([]);
const accountHeads = ref([]);
const contactProfiles = ref([]);
const businesses = ref([]);
const projects = ref([]);
const costCentres = ref([]);
const donors = ref([]);
const ngoabHeads = ref([]);
const budgets = ref([]);
const programs = ref([]);
const projectId = ref(null);
const costCentresId = ref(null);
const donorId = ref(null);
const saveNewLoader = ref(false);
const isEmptyVoucher = ref(false);
const voucherValidationModal = ref(null);
const debit = ref({
    account_head_id: null,
    business_id: null,
    contact_profile_id: null
});
const receipt = ref({
    payment_ref: '',
    payment_ref_date: '',
    date: '',
    note: '',
    attachments: [],
    mop_references: 'mop',
    voucher_prefix : '',
    voucher_serial : '',
    voucher_type: 'receipt_voucher',
    voucher_no : '',
    ledgers: []
});
//computed

const isLoading = computed( () => receiptLoading || saveNewLoader)
const start = computed( () => $route.query.start)
const end = computed( () => $route.query.end)
const general = computed( () => receipt.value.ledgers)
const totalCredit = computed( () => {
    let total = 0;
    general.value.map(i => {
        if(i.credit) total += i.credit
    })
    return total
})
const receiptVoucherPrefix = computed( () => receipt.value.voucher_prefix);
const receiptVoucherSerial = computed( () => receipt.value.voucher_serial);
const voucherQuery = computed(() => {
    return generateQuery($route.params.companyId, 'receipt_voucher', 'general_receipt')
});

//methods
const concatVoucher = () => {
    isEmptyVoucher.value = false;

    if(! receipt.value.voucher_serial || receipt.value.voucher_serial === '') {
        isEmptyVoucher.value = true;
        return ;
    }

    let voucherSerial = decimalFormat(receipt.value.voucher_serial.toString().replace(/[^0-9\.]+/g, ''))
    if(voucherSerial === '') {
        isEmptyVoucher.value = true;
    }
    receipt.value.voucher_serial = voucherSerial;
    receipt.value.voucher_no = `${receipt.value.voucher_prefix}-${voucherSerial}`;
}

const onClose = (index) => {
    receipt.value.ledgers.splice(index, 1)
}
const onClickAdd = () => {
    receipt.value.ledgers.push({
        account_head_id: null,
        debit: null,
        credit: null,
        note: '',
        tax_rate: null,
        taxable_amount: null
    })
}

const setData = () => {
    let ledger = general.value.find(i => i.credit === null)
    if(ledger){
        debit.value.account_head_id = ledger.account_head_id;
        debit.value.business_id = ledger.business_id;
        debit.value.contact_profile_id = ledger.contact_profile_id;
        projectId.value = ledger.project_id;
        costCentresId.value = ledger.cost_centre_id;
        donorId.value = ledger.donor_id;
    }
}

const goToList = () => {
    let params = {
        companyId: $route.params.companyId,
        moduleId: $route.params.moduleId,
        menuId: $route.params.menuId,
        pageId: $route.params.pageId
    }

    let query = {
        start: start.value,
        end: end.value
    }

    let isAllVouchers = $route.query.type && true;
    $router.push({
        query: query,
        name: isAllVouchers ? 'voucher-list' : 'receipt-voucher-np',
        params: params
    })
}

const getFormData = () => {
    let formData = new FormData();
    let previous_file_ids = [];
    receipt.value._method = 'PATCH';
    receipt.value.mop_account_head_id = debit.value.account_head_id;
    Object.keys(receipt.value).forEach(i => {
        if(i !== 'ledgers' && i !== 'business_id' && i !== 'contact_profile_id' && i !== 'attachments') {
            formData.append(i, receipt.value[i])
        }
    })
    let generalLedgerData = general.value.slice()
    let mop_account_head_id = debit.value.account_head_id;
    generalLedgerData.forEach(function(generalLedger) {
      generalLedger.mop_account_head_id = mop_account_head_id;
    });
    let debitObj = generalLedgerData.find(i => i.credit === null)
    debitObj.account_head_id = debit.value.account_head_id,
    debitObj.mop_account_head_id = null,
    debitObj.business_id = debit.value.business_id,
    debitObj.contact_profile_id = debit.value.contact_profile_id,
    debitObj.project_id = projectId.value,
    debitObj.donor_id = donorId.value,
    debitObj.cost_centre_id = costCentresId.value,
    debitObj.debit = totalCredit.value,
    debitObj.debit = totalCredit.value,
    debitObj.note= receipt.value.note
    formData.append('general_ledgers', JSON.stringify(generalLedgerData))
    if(receipt.value.attachments) {
        for(let i=0; i < receipt.value.attachments.length; i++) {
            if(typeof receipt.value.attachments[i] === 'object' && receipt.value.attachments[i].hasOwnProperty('id')) {
                previous_file_ids.push(receipt.value.attachments[i].id)
            } else {
                let key = `attachments[${i}]`;
                let value = receipt.value.attachments[i];
                formData.append(key, value);
            }
        }
        if(previous_file_ids.length) {
            formData.append('previous_file_ids', JSON.stringify(previous_file_ids))
        }
    }
    return formData;
}

const onSelectProject = () => {
    donors.value = [];
    for (let project of projects.value) {
        if(project.id === projectId.value && project.party.hasOwnProperty('id')){
            donors.value.push(project.party);
            break;
        }
    }
}
const updateReceipt = async () => {
    concatVoucher();
    receipt.company_id = receipt.value.company_id = $route.params.companyId;
    let data = getFormData();
    receiptLoading.value = true
    try {
        let res = await editReceipt(receipt.value.id, data)
        if(!res.status) {
            showError(res.message)
        }
        if(res.status) {
            showSuccess(res.message)
             setTimeout(() => {goToList()},1500)
        }
    } catch (err) {
        if(!err.response) {
            showError('Something is wrong. Check your connectivity!!')
            return ;
        }

        if(err.response && err.response.status !== 406) {
            showError(err.response?.data.message)
        }
        if(err.response.data.data && err.response.data.data.show_modal) {
            voucherValidationModal.value.openModal(err.response?.data.message)
        }
    } finally {
        receiptLoading.value = false
    }
}

const setVoucherNumberAndDate = (withoutDate = false) => {
    new Promise(async (resolve, reject) => {
        try {
            if (!withoutDate) {
                receipt.value.date = new Date().toISOString().split('T')[0]
            }
            let voucher = await generateVoucherNumber(voucherQuery.value);
            receipt.value.voucher_prefix = voucher.prefix;
            receipt.value.voucher_serial = voucher.serial;
            resolve();
        } catch (err) {
            reject(err);
        }
    })
}

//Watchers
watch( receiptVoucherPrefix, () => {
    concatVoucher();
})

watch( receiptVoucherSerial, () => {
    concatVoucher();
})

onMounted( () => {
    receiptLoading.value = true
    let companyId = $route.params.companyId;
    let contactId = '';
    let q = '';
    let companyQuery = '?company_id=' + companyId;
    let projectQuery = companyQuery + '&with_donors=1';
    let query = companyQuery + '&q=' + q + '&contact_id' + contactId;
    receipt.value.date = new Date().toISOString().split('T')[0];
    receipt.value.payment_ref_date = new Date().toISOString().split('T')[0];
    receipt.value.company_id = companyId;
    try {
        Promise.all([
            fetchCashAndBank(companyId)
                .then( res => {
                    if(res.data) {
                        cashAndBack.value = res.data
                    }
                }),
            fetchAccountHeads(companyId, "yes")
                .then( res => {
                    if(res.data) {
                        accountHeads.value = res.data
                    }
                }),
            fetchContactProfiles(query)
                .then( res => {
                    if(res.data) {
                        contactProfiles.value = res.data;
                    }
                }),
            fetchContactProfiles(companyQuery)
                .then( res => {
                    if(res.data) {
                        contactProfiles.value = res.data;
                    }
                }),
            fetchProjects(projectQuery)
                .then( res => {
                    if(res.data) {
                        projects.value = res.data;
                    }
                }),
            fetchFD6AccountsAll(companyQuery)
                .then(res => {
                    if(res.data){
                        ngoabHeads.value = res.data
                    }
                }),
            fetchProgramList(companyQuery).then(res => {
                if(res.data){
                    programs.value = res.data;
                }
            }),
            fetchCostCentreList(companyQuery).then(res => {
                if(res.data) {
                    costCentres.value = res.data
                }
            }),
          fetchBusinessList(companyQuery).then(res => {
                if(res.data) {
                    businesses.value = res.data
                }
            }),
            fetchSingleReceipt($route.params.receiptId).then( async res => {
                if (!res.status) {
                    showError(res.message)
                }
                if (res.status) {
                  receipt.value = res.data
                  await setData()
                  onSelectProject();
                  let voucher = editVoucherNumber(receipt.value.voucher_no);
                  receipt.value.voucher_prefix = voucher.prefix;
                  receipt.value.voucher_serial = voucher.serial;
                }
            }).catch( err => {
                if(!err.response) {
                    showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    showError(err.response.message)
                }
            })
        ])
    } catch ( e ) {
        showError('Something went wrong please try again')
    } finally {
        receiptLoading.value = false
    }
})
</script>

<style scoped>
    .cancel-btn{
        background-color: #F0F2F5; color: #7D7D7D;
    }
    .cancel-btn:hover{
        background-color: #e9edf5;
    }
    hr{
        margin: 0 -3%;
        width: 106%;
    }
</style>
